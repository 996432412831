<!--
    业务办理
 -->
<template>
  <div style="width: 100%; margin-top: 1.5vh;">
    <!-- 标题 -->
    <h4>{{ title }}</h4>
    <!-- 内容列表 -->
    <section>
      <ul class="content">
        <li v-for="item in businessData" :key="item.id" @click="$router.push(item.path)">
          <div class="model">
            <img :src="item.url">
            <p>{{ item.subTitle }}</p>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'BusinessProcess',
    data() {
      return {
        title: '业务办理',
        businessData: [
          { id: '001', url: require('../../public/image/businessProcess/icon_1.png'), subTitle: '过户更名', path: '/transferOwnership' },
          { id: '002', url: require('../../public/image/businessProcess/icon_2.png'), subTitle: '阶梯人口', path: '/stepped_population' },
          { id: '003', url: require('../../public/image/businessProcess/icon_3.png'), subTitle: '低保申请', path: '/subsistence_allowance' },
          { id: '004', url: require('../../public/image/businessProcess/icon_4.png'), subTitle: '水表报停', path: '/meter_shutdown' },
          { id: '005', url: require('../../public/image/businessProcess/icon_5.png'), subTitle: '水表复装', path: '/meter_reinstallation' },
          { id: '006', url: require('../../public/image/businessProcess/icon_6.png'), subTitle: '水表销户', path: '/statement_cancellation' },
          { id: '007', url: require('../../public/image/businessProcess/icon_7.png'), subTitle: '用水性质变更', path: '/change_water' },
          { id: '008', url: require('../../public/image/businessProcess/icon_8.png'), subTitle: '用户性质变更', path: '/change_user' },
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  h4 {
    border-left: 1.2vw solid #369EFF;
    border-bottom: 1vw solid #ddebff;
    line-height: 30px;
    padding-left: 2vw;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    .model {
      width: 25vw;
      height: 10vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }
</style>