<template>
  <div class="container">
   <div class="top" >
      <baidu-map class="map"
      :scroll-wheel-zoom="true"
      :center="center"
      :zoom="zoom"
      @ready="initMap"
      ak="tEKMcPRDfSYVEvoqRyZ6AHKjulBsHpZ9"
      >
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
      <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
      <bm-marker :position="center"  @click="infoWindowOpen">
        <!-- <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">高唐县乡村供水运营有限公司</bm-info-window> -->
        <bm-label  content="高唐县乡村供水运营有限公司" :labelStyle="{border:'1px solid blue'}" :offset="{width:-70,height:-30}"></bm-label>
      </bm-marker>
    </baidu-map>
   </div>
   <div class="bottom">
    <div class="bottom-first">
      <div>
        <p>高唐县乡村供水运营有限公司</p>
        <!-- 地图跳转弹框 -->
        <img src="../../assets/images/dingwei.png" alt="" @click="chooseMap">
      </div>
      <div>
        <img src="../../assets/images/duihao2.png" alt="">
      </div>
    </div>
    <ul>
      <li>
        电话：<p>0635-3985110</p>
      </li>
      <li>
        营业时间：8:00 - 18:00
      </li>
      <li class="text">
        地址：山东省聊城市高唐县汇鑫街道高唐县乡村供水运营有限公司
      </li>
    </ul>
   </div>
   <van-popup class="mapChoose" v-model="mapChoose" position="bottom" :style="{ height: '23%' }"  round>
      <div @click="toMap('tengxun')">腾讯</div>
      <div @click="toMap('baidu')">百度</div>
      <div @click="toMap('gaode')">高德</div>
   </van-popup>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import {BmNavigation,BmGeolocation,BmScale,BmInfoWindow,BmMarker,BmLabel} from 'vue-baidu-map'

export default {
  name:'serviceBranch',
  components:{
    BaiduMap,
    BmNavigation,
    BmGeolocation,
    BmScale,
    // BmInfoWindow,
    BmMarker,
    BmLabel
  },
  data(){
    return {
      center:{lng:0,lat:0},
      zoom:15,
      map:null,
      BMap:null,
      show:false,
      mapChoose:false,
    }
  },
  methods:{
    initMap({BMap,map}){
      console.log(BMap,map)
      this.center.lng = 116.215
      this.center.lat = 36.868
      this.zoom = 17
    },
    // 点击弹出信息
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    },
    // 选择地图跳转
    chooseMap(){
      this.mapChoose = true
    },
    toMap(value){
      if(value == 'baidu'){
        // window.location.href ='https://map.baidu.com/?center=161.215,36.868&zoom=14'
        window.location.href ='http://api.map.baidu.com/marker?location=36.868,116.215&zoom=12&title=高唐县乡村供水运营有限公司&content=山东省聊城市高唐县汇鑫街道高唐县乡村供水运营有限公司&output=html'
      }else if(value == 'tengxun'){
        // 腾讯地图
        window.location.href = 'https://mapapi.qq.com/web/mapComponents/locationMarker/v/index.html?type=0&marker=coord:36.868,116.215;title:高唐县乡村供水运营有限公司;addr:山东省聊城市高唐县汇鑫街道高唐县乡村供水运营有限公司'
      }else{
        // 高德地图
        window.location.href ='https://uri.amap.com/marker?position=116.215,36.868&name=高唐县乡村供水运营有限公司'
      }
    }
  }
}
</script>

<style  lang="less" scoped>
.top{
  width: 100%;
  height: 400px;
  background-color: #fff;
  border: 1px solid #ccc;
}
.bottom{
  padding: 20px 10px;
  background-color: #fff;

  &>div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>div:first-child{
      display: flex;
      align-items: center;
      p{
        font-weight: 600;
      }
      img{
        margin-left: 10px;
        width: 30px;
        height: 30px;
      }
    }
    &>div:last-child{
      img{
        width: 20px;
        height: 20px;
      }
    }
  }
  &>ul{
    color: #999;
    li{
      display: flex;
      margin-top: 5px;
      p{
        color:blue ;
      }
      image{
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
}
.map{width: 100%; height: 400px;}
.mapChoose{
  div{
    padding: 15px;
    // height: 30%;
    display: flex;
    justify-content: center;
  }
}
</style>