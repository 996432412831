<template>
  <div class="container">
    <div>
      <van-search v-model.trim="value" placeholder="请输入表号" />
      <button @click="search">查询</button>
    </div>
    <div>
      <div>温馨提示：</div>
      <p>1、绑定方式：用户需输入完整水表号点搜索结果并提交绑定；</p>
      <p>2、提交前请仔细核对个人信息。</p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      value: '',
    }
  },
  methods:{
    // 查询
    search(){
      console.log(this.value,'search')
    }
  }
}
</script>

<style scoped lang="less">
.container{
  padding: 10px;
  background-color: #faf7f7;
  &>div:first-child{
    display: flex;
    justify-content: space-between;
    &>div{
      flex-grow: 1;
      height: 100%;
      &>div{background-color: #fff;}
    }
    button{
      background-color: blue;
      color: #fff;
      width: 100px;
      border-radius: 5px;
      border: none;
    }
  }
  &>div:last-child{
    margin-top: 10px;
    padding: 5px;
    div{
      color:blue;
      font-size: 14px;
    }
    p{
      color: #999;
      margin-top: 20px;
    }
  }
}
</style>