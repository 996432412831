<!--
    水费减免，补偿页面
 -->
<template>
  <div class="container">
    <van-tabs type="card" color="rgb(76, 147, 219)" title-inactive-color="black" animated>
      <van-tab title="水费减免">
        <table border="1">
          <thead>
            <tr>
              <th>水量(m³)</th>
              <th>金额(元)</th>
              <th>原因</th>
              <th>时间</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>3</td>
            </tr>
          </tbody>
        </table>
      </van-tab>
      <van-tab title="水费补偿">
        <table border="1">
          <thead>
            <tr>
              <th>金额(元)</th>
              <th>补偿原因</th>
              <th>时间</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
            </tr>
          </tbody>
        </table>
      </van-tab>
      <van-tab title="水量补偿">
        <table border="1">
          <thead>
            <tr>
              <th>水量(m³)</th>
              <th>补偿原因</th>
              <th>时间</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
            </tr>
          </tbody>
        </table>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  export default {
    name: 'WaterCompensation',
    data() {
      return {
        tableData: [
          { id: '1', amount: '1', reason: '故障补偿', time: '2023-10-30' },
          { id: '2', amount: '1', reason: '故障补偿', time: '2023-10-30' },
          { id: '3', amount: '1', reason: '故障补偿', time: '2023-10-30' }
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    width: 100vw;
    height: 100vh;
  }

  ::v-deep .van-tabs--card>.van-tabs__wrap{
    height: 5vh;
    font-size: 26px;
  }
  ::v-deep .van-tabs__nav--card{
    margin: 0px;
    height: 100%;
  }

  table {
    width: calc(100% - 4vw);
    margin: 2vh 2vw 0 2vw;
    border-collapse: collapse;
    border-spacing: 0;

    th {
      background: #f1f1f1;
    }

    td {
      text-align: center;
    }
  }
</style>