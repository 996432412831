<!--
    用水分析
 -->
<template>
  <div style="width: 100%; height: 100vh;">
    <!-- 顶部个人信息区域 -->
    <section class="header">
      <div class="header-number">
        <p>
          <span style="font-size: 30px;">{{ flowInfo.total }}</span>m³
        </p>
        <p>当年累计用水量</p>
      </div>
      <div style="font-size: 18px;">
        <p>户名: {{ userInfo.username }}</p>
        <p>户号: {{ userInfo.doorNo }}</p>
        <p>地址: {{ userInfo.address }}</p>
      </div>
    </section>

    <!-- 中间部分数据 -->
    <section class="middle">
      <div class="model">
        <p>平均月用水</p>
        <p>
          <span>{{ flowInfo.aver }}</span> m³
        </p>
      </div>
      <div class="model">
        <p>最高月用水</p>
        <p>
          <span>{{ flowInfo.max }}</span> m³
        </p>
      </div>
      <div class="model">
        <p>最低月用水</p>
        <p>
          <span>{{ flowInfo.min }}</span> m³
        </p>
      </div>
    </section>

    <!-- 下方echarts显示 -->
    <van-tabs type="card" color="rgb(76, 147, 219)" title-inactive-color="black" animated @click="onClick">
      <van-tab title="用水趋势">
        <div id="water_trend"/>
      </van-tab>
      <van-tab title="用水明细" style="position: relative;">
        <div id="water_detail"/>
        <span class="date" @click="isShow = true">
          {{ currentDate }} <van-icon name="arrow-down"/>
        </span>
      </van-tab>
    </van-tabs>

    <!-- 选择年月picker -->
    <van-popup v-model="isShow" round position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :formatter="formatter"
      />
    </van-popup>
  </div>
</template>

<script>
  import { initModule } from '@/utils/utils'
  import { getByDoorNo, getFlowDataByDoorNo, getDayFlow } from '@/request/request'

  export default {
    name: 'WaterAnalysis',
    data() {
      return {
        userInfo: {
          username: '',
          doorNo: '',
          address: ''
        },
        flowInfo: {
          min: '',
          max: '',
          aver: '',
          total: ''
        },
        isShow: false,
        option: {
          title: {
            text: ''
          },
          grid: {
            top: '20%',
            right: '5%',
            bottom: '10%',
            left: '12%'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true,
              },
            }
          },
          toolbox: {
            show: true,
            feature: {
              dataView: { readOnly: false },
              magicType: { type: ['line', 'bar'] },
              restore: {}
            }
          },
          xAxis: {
            data: [],
            axisLine: {
              lineStyle: {
                color: '#000',
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#000', //X轴文字颜色
              },
            },
          },
          yAxis: {
            type: 'value',
            name: '用水量(m³)',
            nameTextStyle: {
              color: '#000',
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisTick: {
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#000',
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#000',
              },
            },
          },
          series: {
            name: '用水量(m³)',
            type: 'line',
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: 'circle', //标记的图形为实心圆
            symbolSize: 5, //标记的大小
            lineStyle: {
              color: '#058cff'
            },
            areaStyle: {
              color: 'rgb(76, 147, 219)',
            },
            data: [],
          }
        }
      }
    },
    computed: {
      currentDate: {
        get(){
          let currentDate = new Date()
          let year = currentDate.getFullYear()
          let month = String(currentDate.getMonth() + 1).padStart(2, '0')
          return year + '-' + month
        },
        set(val){
          return val
        }
      }
    },
    methods: {
      /* 时间弹出框时间美化 */
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`
        } else if (type === 'month') {
          return `${val}月`
        }
        return val
      },
      /* 根据户号查询用户绑定信息 */
      getByDoorNo(){
        getByDoorNo(this.$store.state.doorNo)
          .then(res =>{
            this.userInfo = res.data.object
          })
      },
      /* 查询近半年的流量信息 */
      getFlowDataByDoorNo(){
        getFlowDataByDoorNo(this.$store.state.doorNo)
          .then(res => {
            const data = res.data.object
            this.flowInfo = data
            this.option.xAxis.data = data.time.reverse()
            this.option.series.data = data.value.reverse()
            this.option.title.text = '近半年用水量趋势图'
            initModule('water_trend', this.option)
          })
      },
      /* 获取某月的日用水量详细情况 */
      getDayFlow(){
        getDayFlow({ doorNo: this.$store.state.doorNo, date: this.currentDate })
          .then(res => {
            const data = res.data.object
            this.option.xAxis.data = data.time
            this.option.series.data = data.value
            this.option.title.text = ''
            initModule('water_detail', this.option)
          })
      },
      /* tabs点击相应 */
      onClick(_, title){
        if(title === '用水趋势'){
          this.getFlowDataByDoorNo()
        } else if (title === '用水明细') {
          this.getDayFlow()
        }
      }
    },
    mounted() {
      this.getByDoorNo()
      this.getFlowDataByDoorNo()
    }
  }
</script>

<style lang="less" scoped>
  .header {
    width: 90vw;
    height: 22vh;
    padding: 0 5vw;
    background-image: url('../../../public/image/waterServices/analysis-header-bg.png');
    background-size: 100% 100%;
    color: white;

    .header-number {
      height: 8vh;
      margin-bottom: 2vh;
      display: flex;
      justify-content: space-between;
      align-items:flex-end;
      font-size: 18px;
      border-bottom: 1px rgb(202, 199, 199) solid;
    }
  }

  .middle {
    width: 100%;
    display: flex;
    color: #909399;
    border-bottom: 1.5vh solid #F4F4F4;

    .model {
      flex: 1;
      text-align: center;
      padding: 2vh 1vh;

      span {
        margin-top: 1vh;
        display: inline-block;
        font-size: 24px;
        color: black;
      }
    }
  }

  .date {
    position: absolute;
    left: 2vw;
    top: 1vh;
    padding: 0.5vh 2vw;
    background-color: rgb(76, 147, 219);
    color: white;
  }

  #water_detail,
  #water_trend {
    width: 100vw;
    height: 50vh;
  }

  ::v-deep .van-tabs--card>.van-tabs__wrap{
    height: 5vh;
    font-size: 26px;
  }
  ::v-deep .van-tabs__nav--card{
    margin: 0px;
    height: 100%;
  }
</style>