<!--
    用水服务
 -->
<template>
  <div style="width: 100%; margin-top: 1.5vh;">
    <!-- 标题 -->
    <h4>{{ title }}</h4>
    <!-- 内容列表 -->
    <section class="content">
      <div class="content-item" @click="$router.push('/bindDetail')">
        <p>用户管理</p>
        <span>USER</span>
      </div>
      <div class="content-item" @click="$router.push('/waterBill')">
        <p>我的账单</p>
        <span>BILL</span>
      </div>
      <div class="content-item" @click="$router.push('/rechargePay')">
        <p>充值缴费</p>
        <span>PAY</span>
      </div>
      <div class="content-item" @click="$router.push('/rechargeRecord')">
        <p>充值记录</p>
        <span>RECORD</span>
      </div>
      <div class="content-item" @click="$router.push('/waterCompensation')">
        <p>补偿、减免记录</p>
        <span>COMPENSATE</span>
      </div>
      <div class="content-item" @click="$router.push('/waterAnalysis')">
        <p>用水分析</p>
        <span>ANALYSIS</span>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'WaterServices',
    data() {
      return {
        title: '用水服务'
      }
    }
  }
</script>

<style lang="less" scoped>
  h4 {
    border-left: 1.2vw solid #369EFF;
    border-bottom: 1vw solid #ddebff;
    line-height: 30px;
    padding-left: 2vw;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 1vh 6vw;
    justify-content: space-between;

    .content-item {
      width: 42.5vw;
      height: 9vh;
      background-size: 100% 100%;
      margin-bottom: 0.5vh;
      color: white;
      font: 16px bold;

      p {
        margin: 2vh 0 1vh 5vw;
      }

      span {
        display: inline-block;
        margin-left: 5vw;
        border-top: 2px solid white;
      }
    }
  }

  .content-item:nth-child(1) {
    background-image: url('../../public/image/waterServices/bg_1.png');
  }
  .content-item:nth-child(2) {
    background-image: url('../../public/image/waterServices/bg_2.png');
  }
  .content-item:nth-child(3) {
    background-image: url('../../public/image/waterServices/bg_3.png');
  }
  .content-item:nth-child(4) {
    background-image: url('../../public/image/waterServices/bg_4.png');
  }
  .content-item:nth-child(5) {
    background-image: url('../../public/image/waterServices/bg_5.png');
  }
  .content-item:nth-child(6) {
    background-image: url('../../public/image/waterServices/bg_6.png');
  }
</style>