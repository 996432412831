<!--
  用户性质变更页面
 -->
<template>
  <div class="container">
    <!-- 用户信息 -->
    <OldUserInfo/>

  <!-- 用户性质变更部分 -->
    <ChangeInformation title="用户性质变更" applyType="用户性质变更"/>
  </div>
</template>

<script>
  import OldUserInfo from '../../components/domain/OldUserInfo.vue'
  import ChangeInformation from '../../components/domain/ChangeInformation.vue'


  export default {
    name: 'ChangeUser',
    components: { OldUserInfo, ChangeInformation },
    data() {
      return {
        number: '',
        uploader: []
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    width: 94vw;
    height: 96vh;
    padding: 2vh 3vw;
    background-color: rgb(244, 244, 244);
  }

</style>