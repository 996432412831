<!--
    户号情况总览
 -->
<template>
  <div class="container">
    <!-- 顶部图片 -->
    <img src="../../public/image/doorNoProfile/profile_header.png">
    <!-- 户号信息 -->
    <section class="middle">
      <div id="div-image">{{ userInfo.username.substring(0,1) }}</div>
      <div class="user-content" @click="$router.push('/bindDetail')">
        <p>{{ userInfo.adName }} - {{ userInfo.username }}</p>
        <span class="door-no">{{ userInfo.doorNo }}</span>
      </div>
    </section>

    <!-- 水卡信息 -->
    <section>
      <div class="category">居民生活用水</div>
      <div class="bg-icon"></div>
      <div class="water-content">
        <div class="content-info" style="border-right: 1px white solid;">
          <p>账户余额</p>
          <p>
            <span>{{ userInfo.balance }}</span> 元
          </p>
        </div>
        <div class="content-info">
          <p>本月用水</p>
          <p>
            <span>{{ userInfo.flow }}</span> m³
          </p>
        </div>
      </div>
      <button @click="$router.push('/bindDetail')">绑定用户</button>
    </section>
  </div>
</template>

<script>
import {getByDoorNo, getDoorNoByOpenId, getOpenidByCode} from '@/request/request'

export default {
    name: 'DoorNoProfile',
    data() {
      return {
        userInfo: {
          adName: '',
          doorNo: '',
          username: '',
          surplus: '',
          flow: '',
          balance: 0,
          userId: '',
        }
      }
    },
    methods: {
      //通过openid获取默认用户户号
      getByOpenId(){
        getDoorNoByOpenId(this.$store.state.openId)
            .then(res => {
              this.$store.commit('updateDoorNo',res.data.object)
              this.getByDoorNo()
            })
      },
      //通过户号查询用户信息
      getByDoorNo(){
        getByDoorNo(this.$store.state.doorNo)
          .then(res => {
            this.userInfo = res.data.object
            this.$store.commit('updateUserId', this.userInfo.userId)

            console.log( this.userInfo)
            if (typeof this.userInfo === 'object' && this.userInfo !== null) {
              localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
            }
          })
      },
      //获取openid
      getCode() {
        let t = this
        let local = window.location.href
        let appid = 'wx4b0815992024ce83'// 公众号的appid
        t.getUrlCode(local) // 截取code
        if (t.code == null || t.code === '') {
          // 如果没有code，则去请求
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
        } else {
          getOpenidByCode(t.code)
              .then((res) => {
                let result = res.data.object
                this.$store.commit('updateOpenId', result.openid)
                this.getByOpenId()//通过openid获取默认绑定用户
                console.log(result)
                console.log(this.$store.state.openId)
              })
              .catch(e => this.message.error(e))
        }
      },
      getUrlCode(url) {
        let t = this
        // 截取url中的code方法
        let length = url.indexOf('?')
        let str = url.substring(length + 1)
        let strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          if (strs[i].split('=')[0] == 'code') {
            t.code = strs[i].split('=')[1]
          }
        }
      }
    },
  created() {
    if(this.$store.state.openId == '') {
      //获取用户openid
      this.getCode()
    }else {
      this.getByOpenId()
    }
  },
  // mounted(){
  //     this.getByDoorNo()
  //   }
  }
</script>

<style lang="less" scoped>
  .container {
    width: 100%;
    height: 29.5vh;
    background-color: #409EFF;

    img {
      width: 100%;
    }

    .middle {
      display: flex;
      padding: 2vh 6vw 1vh 6vw;
      height: 6vh;
      align-items: center;

      #div-image {
        width: 7vh;
        height: 7vh;
        border-radius: 50%;
        border: 1vw solid #8cc1f3;
        background-color: #6bb7ff;
        color: white;
        font-size: 30px;
        line-height: 7vh;
        text-align: center;
      }

      .user-content {
        color: white;
        margin-left: 2vh;

        .door-no {
          display: inline-block;
          margin-top: 10px;
          border-radius: 15px;
          padding: 2px 10px;
          background-color: #6bb7ff;
        }
      }
    }

    .category {
      position: absolute;
      top: 13%;
      right: 4%;
      text-align: center;
      font-size: 18px;
      width: 30vw;
      height: 5vh;
      transform: rotate(4deg);
      background: #FF7733;
      color: #fff;
      border-radius: 5%;
      padding: 1vh 4vw;
      z-index: 2;
    }

    .bg-icon {
      position: absolute;
      top: 16%;
      right: 40%;
      width: 10vw;
      height: 2vh;
      transform: rotate(5deg);
      background: rgb(229, 210, 188);
      border-radius: 20%;
      z-index: 1;
    }

    .water-content {
      height: 12vh;
      margin: 2vh 6vw 0 6vw;
      background-color: #6bb7ff;
      color: white;
      border-radius: 5px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 3;

      .content-info {
        width: 44vw;
        font-size: 18px;
        text-align: center;

        p {
          margin-top: 1vh;

          span {
            font-size: 22px;
          }
        }
      }
    }

    button {
      position: absolute;
      z-index: 4;
      top: 27%;
      left: 39%;
      color: #1056BE;
      border-radius: 20px;
      padding: 0.5vh 4vw;
      border: 1px solid #ececec;
      box-shadow: 0 0.05333rem 0.13333rem 0.05333rem #ececec;
    }
  }

</style>