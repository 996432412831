<!--
    充值缴费页面
 -->
<template>
  <div class="container">
    <!-- 账户信息 -->
    <section class="content">
      <!-- 账户基础信息 -->
      <ul style="border-bottom: 1px solid #F2F6FC">
        <li>
          <label>户名：</label>
          <span>{{ userInfo.username }}</span>
        </li>
        <li>
          <label>户号：</label>
          <span>{{ userInfo.doorNo }}</span>
        </li>
        <li>
          <label>地址：</label>
          <span>{{ userInfo.address }}</span>
        </li>
        <li>
          <label>表号：</label>
          <span>{{ userInfo.meterNo }}</span>
        </li>
        <li>
          <label>余额：</label>
          <span>
            <i style="color: red; font-weight: bold;">{{ userInfo.balance }}</i> 元
          </span>
        </li>
      </ul>

      <!-- 充值金额部分 -->
      <div class="recharge">
        <h3>充值金额</h3>
        <div style="display: flex; align-items: center; border-bottom: 1px solid #d7dadf;">
          <label>￥</label>
          <van-field v-model="number" type="number" />
        </div>
        <!-- 充值钱数选择 -->
        <van-grid style="margin-top: 2vh;" :border="false">
          <van-grid-item v-for="(item, index) in changeList" :key="index" @click="change(item)" >
            <p>
              <span style="font-size: 18px; font-weight: bold;">{{ item }}</span>元
            </p>
          </van-grid-item>
        </van-grid>
      </div>
    </section>

    <!-- 充值按钮 -->
    <van-button type="info" block style="margin-top: 2vh;" @click="pay">缴费</van-button>

    <!-- 底部跳转 -->
    <p class="text-router">
      <a @click="$router.push('/rechargeRecord')" style="border-right: 1px solid rgb(34, 127, 199);">缴费记录</a>
      <a @click="$router.push('/waterBill')">水费查询</a>
    </p>
  </div>
</template>

<script>
import {getByDoorNo, getOpenidByCode, openWx} from '@/request/request'
import {Dialog, Toast} from 'vant'

  export default {
    name: 'RechargePay',
    data() {
      return {
        userInfo: {
          doorNo: '',
          username: '',
          surplus: '',
          meterNo: '',
          address: ''
        },
        number: 50,
        changeList: [50, 100, 200, 500]
      }
    },
    methods: {
      /* 根据户号获取用户基本信息 */
      getByDoorNo() {
        getByDoorNo(this.$store.state.doorNo)
            .then(res => {
              this.userInfo = res.data.object
            })
      },
      /* 修改充值金额 */
      change(val) {
        this.number = val
      },
      /* 缴费 */
      pay() {
        const money = Math.abs(this.userInfo.surplus)
        if (this.number < money) {
          Dialog.alert({
            message: '缴费金额不能小于代缴金额,请确认!',
          }).then(() => {
          })
        } else {
          openWx({payAmount: this.number,userId: this.$store.state.userId})
              .then((res) => {
                let result = res.data.object
                this.toPay(result)
              })
              .catch(e => {
                Toast.fail('内容解析出错，请联系管理员处理')
              })
        }
      },
      toPay(result) {
        if (typeof WeixinJSBridge == 'undefined') {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
          }
        } else {
          this.onBridgeReady(result)
        }
      },
      onBridgeReady(result) {
        console.log(result)
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
              'appId': result.appId,
              'timeStamp': result.timeStamp,
              'nonceStr': result.nonceStr,
              'package': result.packageVal,
              'signType': result.signType,
              'paySign': result.paySign,
            },
            function (res) {
              if (res.err_msg == 'get_brand_wcpay_request:ok') {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              }
            })
      },
    },
    mounted(){
      this.getByDoorNo()
    }
  }
</script>

<style lang="less" scoped>
  .container {
    width: 94vw;
    height: 96vh;
    background-color: rgb(244, 244, 244);
    padding: 2vh 3vw;
  }

  .content {
    width: 84vw;
    background-color: white;
    padding: 2vh 5vw 0 5vw;
    border-radius: 10px;

    li {
      width: 100%;
      height: 3.5vh;
      line-height: 3.5vh;

      label {
        display: inline-block;
        width: 15vw;
        color: #909399;
      }
    }

    .recharge {
      width: 100%;
      margin-top: 2vh;

      p {
        width: 100%;
        line-height: 30px;
        text-align: center;
        border: 1px solid rgb(34, 127, 199);
        color: rgb(34, 127, 199);
      }
    }
  }

  .text-router {
    margin-top: 3vh;
    text-align: center;
    color: rgb(34, 127, 199);

    a {
      padding: 0 2vw;
    }
  }
</style>