<template>
  <section class="old">
    <h4>报装信息</h4>
    <p>请填写报装信息</p>
    <van-form>
      <div class="first">
        <van-field
        readonly
        clickable
        name="area"
        :value="addressInfo.address"
        label="地区："
        placeholder="请选择所在地区"
        @click="showArea = true"
        :rules="[{ required: true }]"
      />
        <img src="../../assets/images/youjiantou.png" alt="">
      </div>
<van-popup v-model="showArea" position="bottom">
  <!-- 全国省市区 -->
  <van-area
    :area-list="areaList"
    @confirm="onConfirm"
    @cancel="showArea = false"
  />
<!-- 高唐市区域 -->
  <!-- <van-cascader
  v-model="cascaderValue"
  title="请选择所在地区"
  :options="options"
  @close="showArea = false"
  @finish="onFinish"
  >
  </van-cascader> -->
</van-popup>
      <van-field v-model="addressInfo.name" label="上报人：" placeholder="上报人" :rules="[{ required: true }]"/>
      <van-field
      type="tel"
      v-model.trim="addressInfo.phone"
      label="联系电话："
      placeholder="联系电话"
      :rules="[
        { required: true,message:'请输入联系电话' },
        {pattern: /^1[3456789]\d{9}$/,message:'手机号码格式错误！'}
      ]"/>
      <div class="detail">
        <van-field v-model="addressInfo.detail" label="详细地址：" placeholder="详细地址" :rules="[{ required: true }]"/>
        <img src="../../assets/images/dingwei.png" alt="" @click="getAddress">
      </div>
      <van-field v-model="addressInfo.info"
      rows="2" maxlength="50"
      show-word-limit
      autosize
      type="textarea"
      placeholder="请详细描述安装设备"
      :rules="[{ required: true }]"
      />
      <!-- 提交 -->
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" @click="onSubmit">提交</van-button>
      </div>
    </van-form>
    <!-- 地图弹框 -->
    <get-map ref="getMap" @showMap="showMap"></get-map>
    <!-- 进度查询 -->
    <div class="btn-bottom">
      <p>进度查询</p>
    </div>
  </section>
</template>

<script>
  // import { getByDoorNo } from '@/request/request'
  import {areaList} from '@vant/area-data'
  import GetMap from '../../components/GetMap/GetMap.vue'


  export default {
    name: 'serviceWater',
    components:{GetMap},
    data() {
      return {
        addressInfo: {
          address:'',
          name: '',
          detail: '',
          phone: '',
          info: ''
        },
        showArea: false,
        // 全国地区数据
        areaList,
        // 高唐市地区
        // cascaderValue: [],
        // options: []
      }
    },
    methods: {
      // getByDoorNo(){
      //   getByDoorNo(this.$store.state.doorNo)
      //     .then(res => {
      //       this.addressInfo = res.data.object
      //     })
      // },
      // 地区选择
      onConfirm(values) {
      this.addressInfo.address = values.filter((item) => !!item).map((item) => item.name).join('/')
      console.log(this.addressInfo,'user')
      this.showArea = false
    },
    // 提交
    onSubmit(values) {
      // if(){
      //   this.$toast('提交成功')
      // }else{
      //   this.$toast('提交失败')
      // }
      console.log('submit', this.addressInfo,'values', values)
    },
    // 获取地区弹框
    getAddress() {
      this.$refs.getMap.showMap=true
    },
    // 全国地区
    showMap(value){
      this.addressInfo.detail = value
      console.log(this.addressInfo)
    },
     // 高唐市地区
    //  onFinish({ selectedOptions }) {
    //   this.show = false
    //   this.fieldValue = selectedOptions.map((option) => option.text).join('/')
    // },
    },
    mounted(){
      // this.getByDoorNo()
    }
  }
</script>

<style lang="less" scoped>
 .old{
    width: 84vw;
    height: 23vh;
    padding: 2vh 5vw;
    background-color: white;
  }

  h4 {
    color: #409EFF;
    font-size: 18px;
    font-weight: normal;
  }
  p{
    font-size: 12px;
    color: #999;
    margin-top: 5px;
  }
  .van-field {
    padding-left: 0px;
  }
  .first{
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      width: 15px;
      height: 15px;
    }
  }
  .btn-bottom{
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
    color: #409EFF;
    }
  }
  .detail{
    display:flex;
    align-items: center;
    justify-content: space-between;
    img{width: 20px; height: 20px;}
  }
</style>