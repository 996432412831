<!--
    绑定关系详情页面
 -->
<template>
  <div class="container">
    <!-- 已绑定户号关系 -->
    <ul style="padding: 1vh 2vw 0 2vw;">
      <li v-for="item in userData" :key="item.id">
        <van-row>
          <van-col span="18">
            <p style="font-weight: bold;">户名: <span>{{ item.username }}</span></p>
            <p>户号: <span>{{ item.doorNo }}</span></p>
            <p>地址：<span>{{ item.address }}</span></p>
            <p>水表号：<span>{{ item.meterNo }}</span></p>
          </van-col>
          <van-col span="6">
            <van-button
              v-if="item.defaultValue === 'true' || userData.length === 1"
              size="mini"
              round
              @click="unBind(item.openId, item.userId)"
            >解除绑定</van-button>
            <van-button
              v-else-if="item.defaultValue === 'false'"
              size="mini"
              class="btn-true"
              round
              @click="setDefault(item)"
            >默认</van-button>
          </van-col>
        </van-row>
      </li>
    </ul>

    <van-button type="info" class="btn-add" size="large" @click="$router.push('/bindMeterNo')">+ 添加用户</van-button>
  </div>
</template>

<script>
  import { getUserByOpenId, unBind, updateDefault } from '@/request/request'
  import { Dialog, Toast } from 'vant'

  export default {
    name: 'BindDetail',
    data() {
      return {
        userData: []
      }
    },
    methods: {
      /* 根据openId查询关联的全部用户信息 */
      getUserByOpenId(){
        getUserByOpenId(this.$store.state.openId)
          .then(res => {
            this.userData = res.data.list
          })
      },
      /* 解除openId，户号绑定关系 */
      unBind(openId, userId){
        Dialog.confirm({
          message: '是否确认解绑该水表?',
        })
          .then(() => {
            const info =  { userId, openId }
            console.log(info)
            unBind(info)
              .then(() => {
                this.getUserByOpenId()
                Toast.success('水表解绑成功!')
              })
            })
          .catch(() => {}
        )
      },
      /* 设置某个用户为默认显示用户 */
      setDefault(val){
        // 1.修改为默认
        updateDefault({userId: val.userId, openId: val.openId})
          .then(() => {
            Toast.success('设置成功!')
          })
        // 2.修改vuex中的doorNo
        this.$store.commit('updateDoorNo', val.doorNo)
        this.$store.commit('updateUserId', val.userId)
        console.log(this.$store.state.doorNo)
        // 3. 重新进行页面查询
        this.getUserByOpenId()
      }
    },
    mounted(){
      this.getUserByOpenId()
    }
  }
</script>

<style lang="less" scoped>
  .container {
    height: 100vh;
    background: #f4f4f4;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 15vh;
      background-color: white;
      margin-bottom: 1.5vh;

      p {
        padding-left: 4vw;
        line-height: 3vh;
        font-size: 15px;
      }

      .van-button {
        width: 70%;
        margin-top: 40%;
      }

      .btn-true {
        background-color: rgb(8, 193, 196);
        color: white;
      }
    }

    .btn-add {
      margin: 0 1vh;
      width: calc(100% - 2vh);
      color: white;
    }
  }
</style>