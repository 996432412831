<!--
    绑定水表号界面
 -->
<template>
  <div class="container">

    <!-- 搜索框 -->
    <van-search show-action  v-model="meterNo" label="水表号">
      <template #action>
        <div @click="getByMeterNo">搜索</div>
      </template>
    </van-search>

    <!-- 查询到的用户信息 -->
    <div v-if="show">
      <van-radio-group v-model="radio" style="margin-top: 1vh; background-color: white; padding: 0 1vw;">
        <van-radio name="1">
          <div>
            <p style="color: black;">{{ bindInfo.username }}  {{ bindInfo.phone }}</p>
            <P>户号:
              <span>{{ bindInfo.doorNo }}</span>
            </P>
            <p>详细地址:
              <span>{{ bindInfo.address }}</span>
            </p>
            <p>用户表号:
              <span>{{ bindInfo.meterNo }}</span>
            </p>
          </div>
        </van-radio>
      </van-radio-group>
      <van-button type="info" size="large" style="margin-top: 1vh;" @click="addBind">提交</van-button>
    </div>


    <!-- 下方温馨提示 -->
    <div class="bottom">
      <h4>温馨提示: </h4>
      <ul>
        <li>
          <p>1、绑定方式： 用户需输入完整水表号点选搜索结果并提交绑定；</p>
        </li>
        <li>
          <p>2、提交前请仔细核对个人信息。</p>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
  import { getByMeterNo,addBind } from '@/request/request'
  import { Toast } from 'vant'

  export default {
    name: 'BindMeterNo',
    data() {
      return {
        meterNo: '',
        bindInfo: {},
        radio: '',
        show: false
      }
    },
    methods: {
      getByMeterNo(){
        getByMeterNo(this.meterNo)
          .then(res => {
            if(res.data.object === null){
              Toast.fail('查询不到该表号信息，请确认表号！')
              return
            }
            this.show = true
            this.bindInfo = res.data.object
          })
      },
      addBind() {
        this.$router.push('/bindDetail')
        addBind({openId:this.$store.state.openId,userId:this.bindInfo.userId})
            .then(res => {
              if(res.data.code == 200) {
                console.log(this.$store.state.userId)
                this.$store.commit('updateDoorNo', this.bindInfo.doorNo)
                this.$store.commit('updateUserId', this.bindInfo.userId)
                console.log(this.$store.state.userId)
                this.$router.go(0)
                // this.$router.push('/bindDetail')
                // this.$router.back()
              }else {
                Toast.fail('绑定失败！')
              }
            })
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    width: 94vw;
    height: 99vh;
    padding: 1vh 3vw 0 3vw;
    background-color: rgb(244, 244, 244);

    p {
      color: #999;
      margin: 0.5vh 0;

      span {
        color: #327DD1;
      }
    }
  }

  .bottom {
      padding: 2vh 3vw;

      h4 {
        color: rgb(0, 102, 206);
      }

      li {
        margin-top: 2vh;
        color: #909399;
      }
    }
</style>