<!--
    过户更名
 -->
<template>
  <div class="container">
    <!-- 旧的用户信息 -->
    <OldUserInfo/>

    <!-- 新的用户信息 -->
    <section class="new" style="margin-top: 2vh;">
      <div class="new_header">
        <h4>过户更名<span style="color: red;">(必填)</span></h4>
        <p style="font-size: 12px; color: #909399;">请填写过户信息</p>
      </div>
      <van-form>
        <van-field
          v-model="userInfo.username"
          label="新户名："
          placeholder="新户名"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="userInfo.doorNo"
          label="新户户号："
          placeholder="新户户号"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="userInfo.phone"
          label="联系电话："
          placeholder="联系电话"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="userInfo.identityCard"
          label="身份证号："
          placeholder="身份证号"
          :rules="[{ required: true }]"
        />
      </van-form>
    </section>
    <!-- 提交按钮 -->
    <van-button type="info" block style="margin-top: 2vh;" @click="updateUser">提交修改</van-button>
    <!-- 页面跳转 -->
    <p @click="$router.push('/progressQuery')">进度查询</p>
  </div>
</template>

<script>
  import OldUserInfo from '../../components/domain/OldUserInfo.vue'
  import {Toast} from 'vant'
  import {updateUser} from '@/request/request'

  export default {
    name: 'TransferOwnership',
    components: { OldUserInfo },
    data() {
      return {
        userInfo: {
          username: '',
          doorNo: '',
          phone: '',
          identityCard: '',
          id: ''
        },
        errorMessage: ''
      }
    },
    methods:{
      validateForm() {
        if (!this.userInfo.username) {
          return '新户名不能为空'
        }
        if (!this.userInfo.identityCard) {
          return '身份证号不能为空'
        }
        if (!this.userInfo.phone) {
          return '联系方式不能为空'
        }
        if (!this.userInfo.doorNo) {
          return '新户户号不能为空'
        }
        return null
      },
      updateUser() {
        this.errorMessage = this.validateForm()
        if(this.errorMessage) {
          Toast.fail('清完善信息后提交')
          return false
        }
        this.userInfo.id = this.$store.state.userId
        updateUser(this.userInfo)
            .then((res) => {
              if(res.data.code === 200) {
                Toast.success('修改成功')
              }else {
                Toast.success(res.data.info)
              }
            })
            .catch(e => {
              Toast.fail('内容解析出错，请联系管理员处理')
            })
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    width: 94vw;
    height: 96vh;
    padding: 2vh 3vw;
    background-color: rgb(244, 244, 244);
  }

  .new {
    min-height: 300px;
    width: 84vw;
    height: 28vh;
    padding: 2vh 5vw;
    background-color: white;

    .new_header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 1.5vh;
    }
  }

  .van-field {
    padding-left: 0px;
  }

  p {
    margin-top: 2vh;
    text-align: center;
    color: rgb(50, 125, 209);
  }

</style>