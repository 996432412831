<!--
    旧的联系人信息组件
 -->
<template>
  <section class="old">
    <h4>用户信息</h4>
    <van-form>
      <van-field v-model="userInfo.username" label="用户名称：" />
      <van-field v-model="userInfo.doorNo" label="用户户号：" />
      <van-field v-model="userInfo.phone" label="联系电话：" />
      <van-field v-model="userInfo.adName" label="归属地：" />
    </van-form>
  </section>
</template>

<script>
  import { getByDoorNo } from '@/request/request'

  export default {
    name: 'OldUserInfo',
    data() {
      return {
        userInfo: {
          username: '',
          doorNo: '',
          phone: '',
          idCard: ''
        }
      }
    },
    methods: {
      getByDoorNo(){
        getByDoorNo(this.$store.state.doorNo)
          .then(res => {
            this.userInfo = res.data.object
          })
      }
    },
    mounted(){
      this.getByDoorNo()
    }
  }
</script>

<style lang="less" scoped>
 .old{
    min-height: 200px;
    width: 84vw;
    height: 25vh;
    padding: 2vh 5vw;
    background-color: white;
  }

  h4 {
    color: #409EFF;
    font-size: 18px;
    font-weight: normal;
  }

  .van-field {
    padding-left: 0px;
  }
</style>