<!--
    水费账单表格数据
 -->
<template>
  <div class="bill-data-container">
    <!-- 日期时间显示 -->
    <p>{{ tableInfo.time }}月账单</p>

    <!-- 水量明细表格 -->
    <table border="1" style="height: 20vh;">
      <thead>
        <tr>
          <th colspan="4" style="font-size: 14px;">水量明细</th>
        </tr>
        <tr>
          <th>表号</th>
          <th>起码</th>
          <th>止码</th>
          <th>水量(m³)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ tableInfo.address }}</td>
          <td>{{ tableInfo.startNum }}</td>
          <td>{{ tableInfo.endNum }}</td>
          <td>{{ tableInfo.flow }}</td>
        </tr>
        <tr>
          <td>抵扣水量</td>
          <td>/</td>
          <td>/</td>
          <td>0</td>
        </tr>
        <tr>
          <td>合计</td>
          <td>/</td>
          <td>/</td>
          <td>{{ tableInfo.flow }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 账单明细表格 -->
    <table border="1" style="margin-top: 0.5rem;">
      <thead >
        <tr>
          <th colspan="3" style="font-size: 14px;">账单明细</th>
        </tr>
      </thead>
      <tbody >
        <tr>
          <th colspan="3" style="text-align: left; padding-left: 10px;">
            {{ monthDetail[0].name }}
          </th>
        </tr>
        <tr>
          <th>水(m³)</th>
          <th>单价（元）</th>
          <th>金额（元）</th>
        </tr>
        <tr>
          <td>{{ monthDetail[0].flow }}</td>
          <td>{{ monthDetail[0].price }}</td>
          <td>{{ monthDetail[0].cost }}</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th colspan="3" style="text-align: left; padding-left: 10px;">
            {{ monthDetail[1].name }}
          </th>
        </tr>
        <tr>
          <th>水量(m³)</th>
          <th>单价（元）</th>
          <th>金额（元）</th>
        </tr>
        <tr>
          <td>{{ monthDetail[1].flow }}</td>
          <td>{{ monthDetail[1].price }}</td>
          <td>{{ monthDetail[1].cost }}</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th colspan="3" style="text-align: left; padding-left: 10px;">
            {{ monthDetail[2].name }}
          </th>
        </tr>
        <tr>
          <th>水量(m³)</th>
          <th>单价（元）</th>
          <th>金额（元）</th>
        </tr>
        <tr>
          <td>{{ monthDetail[2].flow }}</td>
          <td>{{ monthDetail[2].price }}</td>
          <td>{{ monthDetail[2].cost }}</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td colspan="2" style="text-align: left; padding-left: 10px; font-size: 20px;">
            <b>合计</b>
          </td>
          <td  colspan="1">
            <b style="font-size: 20px;">{{ tableInfo.cost }}</b>元
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'BillData',
    props: ['monthDetail', 'tableInfo']
  }
</script>

<style lang="less" scoped>
  p {
    text-align: center;
    font-weight: bold;
    padding: 0.3rem 0;
  }

  table {
    width: calc(100% - 0.53333rem);
    margin: 0 0.26667rem;
    border-collapse: collapse;
    font-size: 1rem;
    text-align: center;
    border-spacing: 0;

    tr th {
      background-color: #f1f1f1;
      font-weight: bold;
      color: #3f3f44;
      padding: 0.15rem 0;
    }

    tr td {
      padding: 0.2rem 0;
    }
  }
</style>