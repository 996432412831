<!-- eslint-disable vue/no-parsing-error -->
<!--
    水费账单
 -->
<template>
  <div class="container">
    <!-- 户号信息顶部 -->
    <div class="header">
      <p>户号:{{ info.doorNo }}</p>
      <p>户名:{{ info.username }}</p>
    </div>
    <!-- 账单信息 -->
    <section class="footer">
      <div class="blue-line"/>
      <div class="content">
        <!-- 年份选择器 -->
        <span class="date" @click="dateShow = true">
          {{ info.date }}年 <van-icon name="arrow-down" color="#1989fa"/>
        </span>
        <!-- 数据为null时使用 -->
        <!-- <van-empty description="暂无数据" /> -->
        <van-cell-group inset>
          <van-cell
            v-for="item in formattedBillData"
            :key=item.time
            :title=item.time
            :value=item.cost
            @click="getMonthWaterBill(item)"
            is-link
          />
        </van-cell-group>
      </div>
      <div class="arr-icon"></div>
    </section>

    <!-- 日期弹出popup -->
    <van-popup v-model="dateShow" round position="bottom" :style="{ height: '30%' }">
      <van-picker
        title="年份"
        show-toolbar
        :columns="dates"
        @confirm="onConfirm"
        @cancel="dateShow = false"
      />
    </van-popup>

    <!-- 月份账单弹出POPUP -->
    <van-popup v-model="billShow" round position="bottom" :style="{ height: '60%' }" >
      <!-- 水量明细表格 -->
      <BillTable :monthDetail=monthDetail :tableInfo=tableInfo />
    </van-popup>
  </div>
</template>

<script>
  import { getYearBill, getMonthWaterBill } from '@/request/request'
  import BillTable from '@/components/BillTable.vue'

  export default {
    name: 'WaterBill',
    components: { BillTable },
    data() {
      return {
        info: {
          doorNo: '1000548',
          username: '暂时顶替',
          date: undefined
        },
        dateShow: false,  // 日期选择弹窗框
        dates: [],
        billData: [],
        billShow: false,  // 账单详情弹出框
        monthDetail: Array,
        tableInfo: Object
      }
    },
    computed: {
      formattedBillData() {
        return this.billData.map(item => {
          return {
            ...item,
            cost: parseFloat(item.cost).toFixed(2).replace(/\d(?=(\d{3})+(?!\d))/g, ',') + '元' // 添加千位分隔符
          }
        })
      },
    },
    methods: {
      /* 获取20年年份数字 */
      getLastYears() {
        const time = new Date()
        let thisYear = time.getFullYear()
        for (let i = 0; i < 20; i++) {
          this.dates.push(thisYear--)
        }
        this.info.date = this.dates[0]
      },
      /* 获取选择的日期 */
      onConfirm(index) {
        this.currentDate = index
        this.dateShow = false
      },
      /* 获取某年的每月的水费情况 */
      getYearBill(){
        getYearBill(this.info)
          .then(res => {
            this.billData = res.data.list.reverse()
          })
      },
      /* 获取某个月的详细账单情况 */
      getMonthWaterBill(item){
        this.billShow = true
        getMonthWaterBill({doorNo: this.$store.state.doorNo, date: item.time})
          .then(res => {
            this.monthDetail = res.data.list
            this.tableInfo = item
          })
      }
    },
    mounted() {
      this.getLastYears()
      this.getYearBill()
    }
  }
</script>

<style lang="less" scoped>
  .container {
    width: 94vw;
    height: 98vh;
    padding: 1vh 3vw;
    background: linear-gradient(to top, #77baf8, #369eff);
  }

  .header {
    height: 5vh;
    display: flex;
    padding: 0 5vw;
    justify-content: space-between;
    color: white;
    font-size: 18px;
    line-height: 6vh;
  }

  .footer {
    width: 100%;
    height: 93vh;
    margin-top: 1vh;

    .blue-line {
      width: 100%;
      height: 2vh;
      background-color: rgb(0, 102, 204);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    .content {
      width: 100%;
      height: 88vh;
      background-color: white;

      .date {
        width: 100%;
        height: 5vh;
        color: #1989fa;
        font: 22px bolder;
        line-height: 5vh;
        padding-left: 5vw;
      }
    }

    .arr-icon {
      height: 1vh;
      background: url('../../../public/image/waterServices/arr-icon.png') repeat-x;
    }
  }
</style>