<template>
  <div>
    <!-- 地图弹出 -->
    <van-popup v-model="showMap" position="bottom" :style="{ height: '65%' }" closeable round>
      <p style="margin: 20px 40px;text-align: center;font-size: 14px;">点击获取定位</p>
      <baidu-map
      class="map"
      @click="getClickInfo"
      @ready="handler"
      style="height: 60%;width: 100%;"
      ak="tEKMcPRDfSYVEvoqRyZ6AHKjulBsHpZ9"
      >
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
        <bm-marker :position="{lng:`${longitude}`,lat:`${latitude}`}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"></bm-marker>
        <!-- <bm-info-window :position="{lng:longitude,lat:latitude}" :show="infoWindowShow" @ckickclose="infoWindowClose"></bm-info-window> -->
      </baidu-map>
      <div class="bottom">
        <div style="padding: 0 10px;">您当前所在的城市：{{ address }}</div>
        <van-button @click="ok"  style="width: 90%;">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
  import {BmNavigation,BmScale,BmGeolocation,BmMarker} from 'vue-baidu-map'

export default {
  name: 'GetMap',
  components:{
      BaiduMap,
      BmNavigation,
      BmScale,
      BmGeolocation,
      BmMarker,
      // BmInfoWindow
    },
  data(){
    return{
      showMap: false,
      map:null,
      BMap:null,
      point:null,
      address:'',
      longitude:'116.994',
      latitude:'36.666',
      // center:{lng:116.994,lat:36.666}
    }
  },
  methods:{
    // 地图初始化
    handler({BMap,map}){
      this.BMap = BMap
      this.map = map
      // 初始经纬度
      this.point = new BMap.Point(116.994,36.666)
      // 设置地图中心点和缩放级别
      map.centerAndZoom(this.point,14)
    },
    ok(){
      this.showMap=false
      this.$emit('showMap',this.address)
    },
    // 点击获取经纬度
    getClickInfo(e){
      this.longitude=e.point.lng
      this.latitude=e.point.lat
      console.log(e.point)
      const myGeo = new this.BMap.Geocoder  //创建地址解析实例
      myGeo.getLocation(e.point, (rs) => {
        let adr = rs.addressComponents
        //省市区街道门牌号
        this.address =adr.province + adr.city + adr.district + adr.street + adr.streetNumber
        this.infoWindowShow = true
      })
    },
    infoWindowClose(){
      this.latitude = ''
      this.longitude = ''
      this.infoWindowShow = false
    }
  }
}
</script>

<style scoped lang="less">
.bottom{display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div{margin:10px 0;}
  button{
    background-color: #07c160;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
}
</style>