import Vue  from 'vue'
import Vuex from 'vuex'
import { getDoorNoByOpenId } from '@/request/request'

Vue.use(Vuex)

// actions响应组件中的动作
const actions = {
  getDoorNoByOpenId (store, openId) {
    getDoorNoByOpenId(openId)
      .then(res => {
        store.commit('updateDoorNo',res.data.object)
      })
  }
}

// mutations操作数据state
const mutations = {
  updateDoorNo(state, payload) {
    state.doorNo = payload
  },
    updateUserId(state, payload) {
        state.userId = payload
    },
    updateOpenId(state, payload) {
        state.openId = payload
    }
}

// 准备state存储数据
const state = {
  doorNo: '',
  openId: 'oITwL6nHZJrY72L7820a8OPs8IhM',
    // openId: '',
  userId: ''
}

// 创建store并导出
const store = new Vuex.Store({
    actions,
    mutations,
    state
})


//默认导出store
export default store