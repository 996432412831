<!--
    充值记录
 -->
<template>
  <div class="container">
    <!-- 顶部时间选择 -->
    <section class="header">
      <p  @click="isShow = true">
        {{ currentYear }} <van-icon name="arrow-down" />
      </p>
      <span>{{ currentYear }}年累计缴费金额: {{ rechargeCount }}元</span>
    </section>

    <!-- 充值记录列表 -->
    <section class="footer">
      <ul>
        <li v-for="item in rechargeList" :key="item.id">
          <div class="left">
            <p>{{ item.payWay }}</p>
            <p style="margin-top: 1vh; color: #909399;">{{ item.payDate }}</p>
          </div>
          <div class="right">
            <p>
              <span style="font-size: 20px; font-weight: bold;">{{ item.amount.toFixed(2) }}</span> 元
            </p>
            <p :style="getStyleByStatus(item.state)">{{ getTxtByStatus(item.state) }}</p>
          </div>
        </li>
      </ul>

      <!-- 没有数据的情况 -->
      <van-empty
        v-if="!rechargeList || rechargeList.length === 0"
        image="error"
        description="未查询到相关充值记录"
      />
    </section>

    <!-- 日期弹出popup -->
    <van-popup v-model="isShow" round position="bottom" :style="{ height: '40%' }">
      <van-picker
        title="年份"
        show-toolbar
        :columns="dates"
        @confirm="onConfirm"
        @cancel="isShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
  import { getReChange } from '@/request/request'

  export default {
    name: 'RechargeRecord',
    data() {
      return {
        currentYear: '',
        isShow: false,
        dates: [],
        rechargeList: []
      }
    },
    computed:{
      rechargeCount(){
        if (this.rechargeList === null || this.rechargeList === undefined) {
          return 0
        }
        return this.rechargeList.reduce((accumulator, item) => accumulator + item.amount, 0).toFixed(2)
      }
    },
    methods: {
      /* 根据充值状态修改颜色 */
      getStyleByStatus(val){
        switch(val){
          case '1':
            return { color: '#67C23A' }
          default:
            return { color: '#E6A23C' }
        }
      },
      /* 根据充值状态修改文字修饰 */
      getTxtByStatus(val){
        switch(val){
          case '1':
            return '充值成功'
          case '5':
            return '待付款'
          default:
            return '未知状态'
        }
      },
      /* 获取20年年份数字 */
      getLastYears() {
        const time = new Date()
        let thisYear = time.getFullYear()
        for (let i = 0; i < 20; i++) {
          this.dates.push(thisYear--)
        }
        this.currentYear = this.dates[0]
      },
      /* 获取选择的日期 */
      onConfirm(val) {
        this.currentYear = val
        this.isShow = false
        this.getReChange()
      },
      /* 根据年份 + userId 获取充值情况 */
      getReChange(){
        const userId = this.$store.state.userId
        getReChange({ userId, date: this.currentYear })
          .then(res => {
            this.rechargeList = res.data.list
          })
      }
    },
    mounted() {
      this.getLastYears()
      this.getReChange()
    }
  }
</script>

<style lang="less" scoped>
  .container {
    width: 94vw;
    height: 96vh;
    padding: 2vh 3vw;
    background-color: rgb(244, 244, 244);
    overflow: auto;
  }

  .header {
    width: 100%;
    height: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      width: 16vw;
      height: 2vh;
      padding: 0.5vh 2vw;
      border-radius: 15px;
      background-color: white;
    }

    span {
      color: #909399;
      font-size: 14px;
    }
  }

  .footer {
    margin-top: 2vh;

    li {
      width: 100%;
      height: 9vh;
      margin: 1.5vh 0;
      background-color: white;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        padding-left: 5vw;
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 5vw;
      }
    }
  }
</style>