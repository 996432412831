<!--
  过户更名进度查询页面
 -->
<template>
  <div class="container">
    <!-- 下拉刷新 -->
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh"/>
    <ul>
      <li v-for="item in listDate" :key="item.orderId">
        <div class="mark-text">{{ item.status }}</div>
        <div>
          <div class="inner">
            <label>工单号： </label>
            <p style="color: #67C23A;">{{ item.orderId }}</p>
          </div>
          <div class="inner">
            <label>户号： </label>
            <p style="color: #67C23A;">{{ item.doorNo }}</p>
          </div>
          <div class="inner">
            <label>原联系人：</label>
            <p>{{ item.oldUsername }}</p>
          </div>
          <div class="inner">
            <label>新联系人： </label>
            <p>{{ item.newUsername }}</p>
          </div>
          <div class="inner">
            <label>原联系方式：</label>
            <p>{{ item.oldPhone }}</p>
          </div>
          <div class="inner">
            <label>新联系方式： </label>
            <p>{{ item.newPhone }}</p>
          </div>
          <div class="inner">
            <label>身份证号： </label>
            <p>{{ item.idCard }}</p>
          </div>
          <div class="inner">
            <label>归属地：</label>
            <p>{{ item.address }}</p>
          </div>
          <div class="inner">
            <label>当前进度： </label>
            <p>{{ item.process }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import { Toast } from 'vant'

  /* 目前这个地方先这么写死，等后面把数据清空，mounted里面ajax请求获取数据，因为样式都一样，只改请求的数据即可 */

  export default {
    name: 'ProgressQuery',
    data() {
      return {
        listDate: [
          {
            orderId: '123',
            doorNo: '123',
            createTime: '2023-10-22',
            oldUsername: '齐盛',
            newUsername: '齐盛1号',
            oldPhone: '123',
            newPhone: '321',
            idCard: '1234213',
            address: '鱼邱胡居委会',
            process: '正在处理中，请稍后',
            status: '处理中'
          },
          {
            orderId: '1232',
            doorNo: '123',
            createTime: '2023-10-22',
            oldUsername: '齐盛',
            newUsername: '齐盛1号',
            oldPhone: '123',
            newPhone: '321',
            idCard: '1234213',
            address: '鱼邱胡居委会',
            process: '正在处理中，请稍后',
            status: '处理中'
          },
          {
            orderId: '1235',
            doorNo: '123',
            createTime: '2023-10-22',
            oldUsername: '齐盛',
            newUsername: '齐盛1号',
            oldPhone: '123',
            newPhone: '321',
            idCard: '1234213',
            address: '鱼邱胡居委会',
            process: '正在处理中，请稍后',
            status: '处理中'
          },
          {
            orderId: '12321235',
            doorNo: '123',
            createTime: '2023-10-22',
            oldUsername: '齐盛',
            newUsername: '齐盛1号',
            oldPhone: '123',
            newPhone: '321',
            idCard: '1234213',
            address: '鱼邱胡居委会',
            process: '正在处理中，请稍后',
            status: '处理中'
          },
          {
            orderId: '123235',
            doorNo: '123',
            createTime: '2023-10-22',
            oldUsername: '齐盛',
            newUsername: '齐盛1号',
            oldPhone: '123',
            newPhone: '321',
            idCard: '1234213',
            address: '鱼邱胡居委会',
            process: '正在处理中，请稍后',
            status: '处理中'
          }
        ],
        isLoading: false
      }
    },
    methods: {
      /* 下拉屏幕刷新方法 */
      onRefresh() {
        setTimeout(() => {
          Toast('刷新成功')
          this.isLoading = false
        }, 1000)
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    width: 100%;
    min-height: 100vh;
    background-color: rgb(244, 244, 244);
  }

  li {
    position: relative;
    background-color: white;
    margin: 2vh 5vw;
    padding: 2vh 5vw;

    .inner {
      display: flex;
      align-items: center;

      label {
        width: 25vw;
        text-align: right;
        color: #909399;
        line-height: 26px;
      }
    }

    .mark-text {
      width: 15vw;
      height: 3vh;
      line-height: 3vh;
      padding: 0.5vh 1vw;
      text-align: center;
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      background-color: #409EFF;
    }
  }
</style>