<!--
  阶梯人口
 -->
<template>
  <div class="container">
    <!-- 用户信息 -->
    <OldUserInfo/>

    <!-- 阶梯人口部分 -->
    <section class="new" style="margin-top: 2vh;">
      <div class="new_header">
        <h4>阶梯人口<span style="color: red;">(必填)</span></h4>
        <p style="font-size: 12px; color: #909399;">请填写人口信息</p>
      </div>
      <van-form>
        <van-field name="stepper" label="人口数量">
          <template #input>
            <van-stepper v-model="profession.populaceNum" />
          </template>
        </van-field>
        <van-field name="uploader" label="文件上传">
          <template #input>
            <van-uploader v-model="fileList" :before-read="beforeRead" :after-read="afterRead" :max-count="2"/>
          </template>
        </van-field>
      </van-form>
    </section>

    <!-- 提交按钮 -->
    <van-button type="info" block style="margin-top: 2vh;" @click="addProfession">提交</van-button>
    <!-- 页面跳转 -->
    <p @click="$router.push('/progressQuery')">进度查询</p>
  </div>
</template>

<script>
  import OldUserInfo from '../../components/domain/OldUserInfo.vue'
  import {addProfession, uploadFile} from '@/request/request'
  import {Toast} from 'vant'

  export default {
    name: 'SteppedPopulation',
    components: { OldUserInfo },
    data() {
      return {
        profession: {
          userId: '',
          userName: '',
          applyIdNumber: '',
          contactInfo: '',
          imageInfo: '',
          applyType: '阶梯人口',
          applyUserName: '',
          userCode: '',
          applyCause: '',
          operator: '',
          operatorContactInfo: '',
          populaceNum: '0'
        },
        fileList: [],
        imageList:[]
      }
    },
    methods: {
      addProfession() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.profession.userName = userInfo.username
        this.profession.userId = this.$store.state.userId
        // this.profession.applyUserName = this.profession.userName
        this.profession.userCode = this.$store.state.doorNo
        this.profession.imageInfo = '{' + this.imageList.join(',') + '}'
        this.profession.homeLocation = userInfo.address
        this.profession.contactInfo = userInfo.phone
        addProfession(this.profession)
            .then((res) => {
              if(res.data.code === 200) {
                Toast.success('提交成功')
              }else {
                Toast.success(res.data.info)
              }
            })
            .catch(e => {
              Toast.fail('内容解析出错，请联系管理员处理')
            })
      },
      afterRead(files, detail) {
        this.file = files.file
        const formData = new FormData()
        formData.append('file', this.file)
        // console.log(files, detail)
        uploadFile(formData)
            .then((res) => {
              if(res.data.code === 200) {
                let image = '"fileList":' + res.data.info
                this.imageList.push(image)
                console.log(this.imageList)
                // this.profession.imageInfo.append(image)
                // console.log(this.profession)
                Toast.success('上传成功')
                setTimeout(() => {
                  files.status = null // 上传成功后须将file对象的status属性置为null，否则遮罩不会消失。
                }, 2000)
              }else {
                Toast.success(res.data.info)
              }
            })
            .catch(e => {
              Toast.fail('内容解析出错，请联系管理员处理')
            })
      },
      beforeRead(file) {
        if (file.type !== 'image/jpeg') {
          Toast('请上传 jpg 格式图片')
          return false
        }
        return true
      },
    }
  }
</script>

<style lang="less" scoped>
  .container {
    //min-height: 700px;
    width: 94vw;
    height: 96vh;
    padding: 2vh 3vw;
    background-color: rgb(244, 244, 244);
  }

  .new {
    min-height: 300px;
    width: 84vw;
    height: 26vh;
    padding: 2vh 5vw;
    background-color: white;

    .new_header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 1.5vh;
    }
  }

  .van-field {
    padding-left: 0px;
  }

  p {
    margin-top: 2vh;
    text-align: center;
    color: rgb(50, 125, 209);
  }

</style>