import instance from './interceptors'

// 根据水表号查询用户绑定信息
export function getByMeterNo (meterNo) {
  meterNo = meterNo || null
  const url = `/swchat/user-bind/${meterNo}`
  return instance.get(url)
  // return null
}
// 获取某年的每月的水费情况
export function getYearBill (data) {
  const url = '/swchat/bill-data/get-year-bill'
  return instance.post(url, data)
  // return null
}
// 获取某月水费详细情况
export function getMonthWaterBill (data) {
  const url = '/swchat/water-bill/month-water-bill'
  return instance.post(url, data)
  // return null
}
// 根据户号查询用户绑定信息
export function getByDoorNo (doorNo) {
  const url = `/swchat/user-bind/user/${doorNo}`
  return instance.get(url)
  // return null
}
// 根据户号查询某段时间的流量情况
export function getFlowDataByDoorNo (doorNo) {
  const url = `/swchat/flow-data/${doorNo}`
  return instance.get(url)
  // return null
}
// 获取某月的日用水量详细情况
export function getDayFlow (data) {
  const url = '/swchat/flow-data'
  return instance.post(url, data)
  // return null
}
// 根据openId查询关联的全部用户信息
export function getUserByOpenId (openId) {
  const url = `/swchat/user-bind/list/${openId}`
  return instance.get(url)
  // return null
}
// openId 用户解绑
export function unBind (data) {
  const url = '/swchat/user-bind/unbind'
  return instance.post(url, data)
  // return null
}

export function addBind (data) {
  const url = '/swchat/user-bind/add-bind'
  return instance.post(url, data)
  // return null
}
// 修改用户的默认绑定
export function updateDefault (data) {
  const url = '/swchat/user-bind'
  return instance.put(url, data)
  // return null
}
// 根据openId查询当前默认用户
export function getDoorNoByOpenId (openId) {
  const url = `/swchat/user-bind/openId/${openId}`
  return instance.get(url)
  // return null
}
// 根据年份 + userId 获取充值情况
export function getReChange (data) {
  const url = '/swchat/recharge'
  return instance.post(url, data)
  // return null
}
// 测试是否可以打开微信支付
export function openWx (data) {
  const url = '/swchat/wx/wx-pay'
  return instance.post(url,data)
}
//通过code获取openid
export function getOpenidByCode (code) {
  const url = `/swchat/wx/get-openid/${code}`
  return instance.get(url)
}

//修改用户信息
export function updateUser (data) {
  const url = '/swchat/user/update-user'
  return instance.post(url,data)
}

export function addProfession(data) {
  const url = '/swchat/profession/add'
  return instance.post(url,data)
}

export function uploadFile(data) {
  const url = '/swchat/upload'
  return instance.post(url,data)
}