var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bill-data-container"},[_c('p',[_vm._v(_vm._s(_vm.tableInfo.time)+"月账单")]),_c('table',{staticStyle:{"height":"20vh"},attrs:{"border":"1"}},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.tableInfo.address))]),_c('td',[_vm._v(_vm._s(_vm.tableInfo.startNum))]),_c('td',[_vm._v(_vm._s(_vm.tableInfo.endNum))]),_c('td',[_vm._v(_vm._s(_vm.tableInfo.flow))])]),_vm._m(1),_c('tr',[_c('td',[_vm._v("合计")]),_c('td',[_vm._v("/")]),_c('td',[_vm._v("/")]),_c('td',[_vm._v(_vm._s(_vm.tableInfo.flow))])])])]),_c('table',{staticStyle:{"margin-top":"0.5rem"},attrs:{"border":"1"}},[_vm._m(2),_c('tbody',[_c('tr',[_c('th',{staticStyle:{"text-align":"left","padding-left":"10px"},attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.monthDetail[0].name)+" ")])]),_vm._m(3),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.monthDetail[0].flow))]),_c('td',[_vm._v(_vm._s(_vm.monthDetail[0].price))]),_c('td',[_vm._v(_vm._s(_vm.monthDetail[0].cost))])])]),_c('tbody',[_c('tr',[_c('th',{staticStyle:{"text-align":"left","padding-left":"10px"},attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.monthDetail[1].name)+" ")])]),_vm._m(4),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.monthDetail[1].flow))]),_c('td',[_vm._v(_vm._s(_vm.monthDetail[1].price))]),_c('td',[_vm._v(_vm._s(_vm.monthDetail[1].cost))])])]),_c('tbody',[_c('tr',[_c('th',{staticStyle:{"text-align":"left","padding-left":"10px"},attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.monthDetail[2].name)+" ")])]),_vm._m(5),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.monthDetail[2].flow))]),_c('td',[_vm._v(_vm._s(_vm.monthDetail[2].price))]),_c('td',[_vm._v(_vm._s(_vm.monthDetail[2].cost))])])]),_c('tbody',[_c('tr',[_vm._m(6),_c('td',{attrs:{"colspan":"1"}},[_c('b',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.tableInfo.cost))]),_vm._v("元 ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"font-size":"14px"},attrs:{"colspan":"4"}},[_vm._v("水量明细")])]),_c('tr',[_c('th',[_vm._v("表号")]),_c('th',[_vm._v("起码")]),_c('th',[_vm._v("止码")]),_c('th',[_vm._v("水量(m³)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("抵扣水量")]),_c('td',[_vm._v("/")]),_c('td',[_vm._v("/")]),_c('td',[_vm._v("0")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"font-size":"14px"},attrs:{"colspan":"3"}},[_vm._v("账单明细")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("水(m³)")]),_c('th',[_vm._v("单价（元）")]),_c('th',[_vm._v("金额（元）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("水量(m³)")]),_c('th',[_vm._v("单价（元）")]),_c('th',[_vm._v("金额（元）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("水量(m³)")]),_c('th',[_vm._v("单价（元）")]),_c('th',[_vm._v("金额（元）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"text-align":"left","padding-left":"10px","font-size":"20px"},attrs:{"colspan":"2"}},[_c('b',[_vm._v("合计")])])
}]

export { render, staticRenderFns }