<!--
  水表销户页面
 -->
<template>
  <div class="container">
    <!-- 用户信息 -->
    <OldUserInfo/>

  <!-- 水表销户部分 -->
    <ChangeInformation title="水表销户" applyType="水表销户"/>
  </div>
</template>

<script>
  import OldUserInfo from '../../components/domain/OldUserInfo.vue'
  import ChangeInformation from '../../components/domain/ChangeInformation.vue'

  export default {
    name: 'StatementCancellation',
    components: { OldUserInfo, ChangeInformation },
    data() {
      return {
        number: '',
        uploader: []
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    width: 94vw;
    height: 96vh;
    padding: 2vh 3vw;
    background-color: rgb(244, 244, 244);
  }


</style>