<template>
  <div style="height: 100%; width: 100%;">

    <!-- 顶部户号信息部分 -->
    <DoorNoProfile/>

    <!-- 中间用水服务部分 -->
    <WaterServices/>

    <!-- 下方业务办理部分 -->
    <BusinessProcess/>
  </div>
</template>

<script>
  import BusinessProcess from '@/components/BusinessProcess.vue'
  import DoorNoProfile from '@/components/DoorNoProfile.vue'
  import WaterServices from '@/components/WaterServices.vue'

  export default {
    name: 'HomePage',
    components: {
      DoorNoProfile,
      WaterServices,
      BusinessProcess
    },
    mounted() {
    }
  }
</script>