<template>
  <div class="App">
    <router-view></router-view>
  </div>
</template>

<script>
import '@/assets/css/global.css'
import 'animate.css'

export default {
  name: 'App'
}
</script>

<style>
  html,
  body,
  #App {
    width: 100%;
    height: 100%;
  }
</style>
