import { localUrl } from '@/config/global'
import { ceshiUrl } from '@/config/global'
import axios from 'axios'
import { Toast } from 'vant'

// 创建axios实例
const instance = axios.create({
  baseURL: localUrl, // api的base_url
  timeout: 5000 // 请求超时时间
})

// 响应拦截器
instance.interceptors.response.use(
  response => {
    return Promise.resolve(response)

    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === 200) {
      if (response.data.code === 200) { // 请求到正常数据
        return Promise.resolve(response)
      } else if (response.data.code === 401) {  // 没有请求到数据
        Toast.fail(response.data.info)
        return Promise.resolve(response)
      } else {
        Toast.fail('请求结果数据异常,请查明问题源泉!')
        return Promise.resolve(response)
      }
    } else {
      Toast.fail('内容解析出错，请联系管理员处理')
      return Promise.reject(response)
    }
  },
  error => {
    console.log(error)
    if (error.response) {
      // 如果状态码不是2xx，处理错误
      switch (error.response.status) {
        case 404:
          Toast.fail('网络请求不存在')
          break
        default:
          console.log(error.response.status)
          Toast.fail('内容解析出错，请联系管理员处理')
          return false
      }
    }
    return Promise.reject(error)
  }
)

export default instance