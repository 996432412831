import Vue from 'vue'
import VueRouter from 'vue-router'

/* 引入各种组件 */
import Homepage from '@/views/HomePage.vue'
import BindDetail from '@/views/doorNoInfo/BindDetail.vue'
import BindMeterNo from '@/views/doorNoInfo/BindMeterNo.vue'
import WaterBill from '@/views/waterServerSection/WaterBill.vue'
import RechargePay from '@/views/waterServerSection/RechargePay.vue'
import RechargeRecord from '@/views/waterServerSection/RechargeRecord.vue'
import WaterCompensation from '@/views/waterServerSection/WaterCompensation.vue'
import WaterAnalysis from '@/views/waterServerSection/WaterAnalysis.vue'
import TransferOwnership from '@/views/businessProcess/TransferOwnership'
import ProgressQuery from '@/views/businessProcess/ProgressQuery'
import SteppedPopulation from '@/views/businessProcess/SteppedPopulation'
import SubsistenceAllowance from '@/views/businessProcess/SubsistenceAllowance'
import MeterShutdown from '@/views/businessProcess/MeterShutdown'
import MeterReinstallation from '@/views/businessProcess/MeterReinstallation'
import StatementCancellation from '@/views/businessProcess/StatementCancellation'
import ChangeWater from '@/views/businessProcess/ChangeWater'
import ChangeUser from '@/views/businessProcess/ChangeUser'
import ServiceBranch from '@/views/customerService/serviceBranch'
import ServiceWater from '@/views/customerService/serviceWater'
import ServiceRepair from '@/views/customerService/serviceRepair'



Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/homePage'
    },
    {
      path: '/homePage',
      name: 'homePage',
      component: Homepage
    },
    {
      path: '/bindDetail',
      name: 'bindDetail',
      component: BindDetail
    },
    {
      path: '/bindMeterNo',
      name: 'bindMeterNo',
      component: BindMeterNo
    },
    {
      path: '/waterBill',
      name: 'waterBill',
      component: WaterBill
    },
    {
      path: '/rechargePay',
      name: 'rechargePay',
      component: RechargePay
    },
    {
      path: '/rechargeRecord',
      name: 'rechargeRecord',
      component: RechargeRecord
    },
    {
      path: '/waterCompensation',
      name: 'waterCompensation',
      component: WaterCompensation
    },
    {
      path: '/waterAnalysis',
      name: 'waterAnalysis',
      component: WaterAnalysis
    },
    {
      path: '/transferOwnership',
      name: 'transferOwnership',
      component: TransferOwnership
    },
    {
      path: '/progressQuery',
      name: 'progressQuery',
      component: ProgressQuery
    },
    {
      path: '/stepped_population',
      name: 'SteppedPopulation',
      component: SteppedPopulation
    },
    {
      path: '/subsistence_allowance',
      name: 'SubsistenceAllowance',
      component: SubsistenceAllowance
    },
    {
      path: '/meter_shutdown',
      name: 'MeterShutdown',
      component: MeterShutdown
    },
    {
      path: '/meter_reinstallation',
      name: 'MeterReinstallation',
      component: MeterReinstallation
    },
    {
      path: '/statement_cancellation',
      name: 'StatementCancellation',
      component: StatementCancellation
    },
    {
      path: '/change_water',
      name: 'ChangeWater',
      component: ChangeWater
    },
    {
      path: '/change_user',
      name: 'ChangeUser',
      component: ChangeUser
    },
    {
      path: '/Service_branch',
      name: 'ServiceBranch',
      component: ServiceBranch
    },
    {
      path: '/Service_water',
      name: 'ServiceWater',
      component: ServiceWater
    },
    {
      path: '/Service_repair',
      name: 'ServiceRepair',
      component: ServiceRepair
    }
  ]
})